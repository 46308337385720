import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-component',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {


  @Input() data: any;

  constructor(
    private router: Router) { }

  ngOnInit() { }


  navigateToHome() {
    this.router.navigate(['/configuration'])
  }

}
