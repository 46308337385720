import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { PlanService } from '../_services/plan.service';
import { AlertService } from './alerts.service';

@Injectable({
    providedIn: 'root'
})

export class PlanPermissionService {
    private permissions;

    constructor(
        private storage: Storage,
        private planService: PlanService,
        private alertService: AlertService,
    ) {
    }

    async start() {
        const val = await this.storage.get('user');
        if (val) {
            const result = await this.planService.getPlanByEnterpriseId().toPromise();
            console.log(result)
            this.permissions = result;
            return true;
        } else {
            return false;
        }
    }

    public async checkUserPlanPermission(currentUsers: number): Promise<boolean> {
        if (!this.permissions) {
            const result = await this.start();
            if (!result) {
                return false;
            }
        }

        const maxUsers = this.permissions.features.users;

        if (currentUsers < maxUsers) {
            return false;
        }
        return true;
    }

    public async checkPlanPermission(featureName: string): Promise<boolean> {
        if (!this.permissions) {
            const result = await this.start(); // Espera a que this.permissions se inicialice antes de continuar
            if (!result) {
                return false;
            }
        }

        const feature = this.permissions.features[featureName].isEnabled;
        if (!feature) {
            return false;
        }

        return true;
    }

}


