import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '../_services/notification.service';
import { EventService } from '../_services/event.service';
import { EventsService } from '../_services/events.service';
import { LoginService } from '../_services/login.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  notifications = [];
  filteredNotifications = [];
  loadingMessages = true;
  eventsSuscription;
  isStarted: boolean = false;
  user: string;
  notificationsSubscription;
 
  @Output() notificationsChanged = new EventEmitter<boolean>();

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;

    const miDiv = document.getElementById('miDiv');

    if (!miDiv.contains(targetElement) && this.isStarted == true) {
      this.eventsService.emitEventValue('notification', false);
    }
  }

  constructor(
    private notificationService: NotificationService ,
    private eventService: EventService,
    private eventsService: EventsService,
    private loginService : LoginService
      ) { }

  ngOnInit() {
    setTimeout(()=>{
      this.isStarted = true;
    }, 0)

    this.eventService.publishSomeData(true);
    this.user = this.loginService.getUserId();
    // this.notificationService.list().subscribe(data=>{
    //   this.prepareData(data);
    // })
    // this.notificationService.listByCausa().subscribe(data=>{
    //   this.prepareData(data);
    // })

    this.notificationsSubscription = this.notificationService.getNotificationsArrayObservable().subscribe(data =>{
      //console.log("NOTIFICATION COMPONENT --> NOTIFICATIONS ", data);
      this.prepareData(data)
    })  

  }

  readedByUser(notification){
    if( notification.data.readedBy.length == 0) {return false}
    return notification.data.readedBy.find( userId => userId === this.user );
  } 
  
  prepareData(data : any[]){
     this.notifications = data;
    this.filteredNotifications = this.addVisibibilityNotifications();
    this.sortByDate();
    this.loadingMessages = false;      
  }
  formatedDate(dateValue){
    let date = new Date(dateValue)

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    if(month < 10){
      dateValue = day+"-"+"0"+month+"-"+year;
    }else{
      dateValue = day+"-"+month+"-"+year;
    }
    return dateValue
  }

  sortByDate(){
      var value = this.filteredNotifications;
      this.filteredNotifications = value.sort(function (a,b){
          return  new Date(b.date).getTime() - new Date(a.date).getTime();
      })
      this.filteredNotifications.reverse()
      //console.log('data:',data);
  }

  addVisibibilityNotifications(){
    const notifications = [];
    for(let i = 0 ; i < this.notifications.length ; i ++){
      if(this.notifications[i].status){
        this.notifications[i]['visibility'] = false;
        notifications.push(this.notifications[i]);
      }
    }

    return notifications;
  }

  filterNotifications(){
    var filteredNotifications = [];
    for(let i = 0 ; i < this.filteredNotifications.length ; i ++){
      if(this.filteredNotifications[i].status){
        filteredNotifications.push(this.filteredNotifications[i]);
      }
    }

    return filteredNotifications;
  }

  changeNotificationVisibility(element){
    
    if( !this.readedByUser(element) ){
      this.notificationService.read(element._id).subscribe( res =>{
       element.data.readedBy.push(this.user);
        this.notificationsChanged.emit(true);
      })
    }
    
    element.visibility = !element.visibility;
  }

  //para eliminar notificacion - PENDING.
  changeNotificationStatus(element){
    element.status = !element.status;
  }

  ngOnDestroy(){
    this.isStarted = false;
    this.notificationsSubscription.unsubscribe();
  }
}
