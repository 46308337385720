import { convertToBytes } from 'convert-file-size-to-bytes';
import { Injectable } from '@angular/core';

@Injectable()

export class UtilsService {

  constructor() { }

  checkSpace(capacity: string, usedSpace: string) {
    const convertedCapacity = convertToBytes(capacity);
    const convertedUsedSpace = convertToBytes(usedSpace);
    if(convertedUsedSpace > convertedCapacity){
        return true;
    } else {
        return false;
    }
  }
};
