import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
//------------UploadBox imports------------//
import { NgxFileDropModule } from 'ngx-file-drop';
import { UploadBoxComponent } from './upload-box-component/upload-box-component.component';
//-----------------------------------------//
import { ColorGeneratorPage } from './color-generator/color-generator.page';
import { HasPermissionDirective } from './_directives/permission.directive';
import { CausaCardComponent } from './components/causa-card/causa-card.component';
import { ErrorComponent } from './components/error/error.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgxFileDropModule
  ],
  declarations: [ ColorGeneratorPage,HasPermissionDirective,UploadBoxComponent,CausaCardComponent,ErrorComponent],
  exports: [ ColorGeneratorPage, HasPermissionDirective, UploadBoxComponent,CausaCardComponent,ErrorComponent],
})
export class CommonComponentModule {}