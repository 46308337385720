import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { DataStorageService } from './_services/dataStorage.service';

import { EventService } from './_services/event.service';
import { EventsService } from './_services/events.service';
import { Storage } from '@ionic/storage-angular';

import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MenuService } from './_services/menu.service';
import { LoginService } from './_services/login.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent implements AfterViewInit {

  overlayStatus = false;
  eventsSuscription = new Subject<Boolean>();
  storeReady = false;
  menuStatus = true;
  tooltipText = '';

  routes = [];

  @Output() appRoutes = new EventEmitter<any[]>();



  constructor(
    public dataStorage: DataStorageService,
    public router: Router,
    public eventService: EventService,
    private eventsServices: EventsService,
    private storage: Storage,
    private menuService: MenuService,
    private loginService : LoginService,
    private platform : Platform) { }


  async ngOnInit() {

    await this.storage.create().then( () =>{

      //Hide Menu
      this.menuService.hideSidebar();

      this.storeReady = true;

      //Menu size emitter
      this.eventsServices.addEventEmitter('menu');
      const segment = this.eventsServices.getEventEmitter('menu');
      document.documentElement.style.setProperty('--menu-size', '19vw');
      segment.subscribe((value: any) => {
        document.documentElement.style.setProperty('--menu-size', value.size);
        this.menuStatus = value.status;
      });

      //Menu data emitter
      const menuData = this.eventsServices.getEventEmitter('appPages');
      menuData.subscribe( data =>{
        this.routes = data;
      })

      //header data emitter
      //this.eventsServices.addEventEmitter('userLetter');
    });
    

  }

  async ngAfterViewInit() {


  }

  changeOverlayStatus() {
    //this.overlayStatus = !this.overlayStatus;
  }

  activateHover(index) {
    this.tooltipText = this.routes[index].title;
    const element = document.getElementById(index);
    const elementPosition = element.getBoundingClientRect();
    document.documentElement.style.setProperty('--tooltip-left', `${elementPosition.x}px`);
    document.documentElement.style.setProperty('--tooltip-top', `${elementPosition.y}px`);
    document.documentElement.style.setProperty('--tooltip-visibility', `visible`);
  }

  desactivateHover() {
    document.documentElement.style.setProperty('--tooltip-visibility', `hidden`);
  }


}
