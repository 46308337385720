import { Component, OnInit, HostListener } from '@angular/core';
import { InfoService } from '../_services/info.service';
import { EventsService } from '../_services/events.service';
@Component({
  selector: 'app-quick-start',
  templateUrl: './quick-start.page.html',
  styleUrls: ['./quick-start.page.scss'],
})
export class QuickStartPage implements OnInit {
  isStarted = false;

  steps = [
    {title:'Primeros Pasos', status:false, visibility:false, description: this.infoService.textRichFirstStep, completed: false},
    {title:'Causas', status:false, visibility:false, description:this.infoService.textRichCausa, completed: false},
    {title:'Consultas', status:false, visibility:false, description:this.infoService.textRichConsulta, completed: false},
    {title:'Formatos', status:false, visibility:false, description:this.infoService.textRichFormato, completed: false}

  ];

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;

    const quickStartElement = document.getElementById('quickStart');
    if (!quickStartElement.contains(targetElement) && this.isStarted == true) {
      this.eventsService.emitEventValue('quick-start', false);
    }
  }

  constructor(private infoService: InfoService, private eventsService: EventsService) { }

  ngOnInit() {
    setTimeout(()=>{
      this.isStarted = true;
    }, 500)
  }

  changeStepStatus(index){
    this.steps[index].status = !this.steps[index].status
  }

  changeStepVisibility(index){
    this.steps[index].visibility = !this.steps[index].visibility
  }

}
