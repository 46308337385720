import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { AlertService } from 'src/app/_services/alerts.service';
import { FileService } from 'src/app/_services/file.service';
import { DisplayPage } from 'src/app/display/display.page';

@Component({
  selector: 'app-causa-card',
  templateUrl: './causa-card.component.html',
  styleUrls: ['./causa-card.component.scss'],
})
export class CausaCardComponent implements OnInit {

  @Input() item = [];
  loadingInterval;
  loading;
  message: string = 'Cargando...';

  constructor(
    private loadingCtrl  : LoadingController,
    private modalCtrl : ModalController,
    private fileService : FileService,
    private alertService : AlertService
  ) { }

   ngOnInit() {
  }


  async showLoading() {
    this.loadingInterval = setInterval(()=>{
      const messages = [
        'Esta documento está esperando un poco más de lo esperado 🙀',
        'Espera un poco más porfavor 🥵',
        'Danos un momento ⏰',
        'Queda poquito, muy poquito 👌',
        'Ya casi estamos 🧰',
        'Solo unos bytes mas 💻'
      ];
      const index = Math.floor(Math.random() * (messages.length));
      this.loading.message = messages[index];

    },3000)
    this.loading = await this.loadingCtrl.create({
      message: this.message,
      duration: 300000,
      cssClass: 'custom-loading',
    });
    
    
    this.loading.present();        
  }
  
  async dismissLoading(){
    this.loading.dismiss();
    clearInterval(this.loadingInterval);
  }

  async openModal(file){
    const modal = await this.modalCtrl.create({
      component: DisplayPage,
      cssClass: 'modals',
      componentProps:{     
        "currentFile" : file     
      }
    });

    modal.onDidDismiss().then(modal=>{
      //console.log('modal dismissed')
    });

    return await modal.present();   
  }

  showFile(docto){
    //console.log("docto",docto)
    this.showLoading();    
    const data = docto.data
    this.fileService.readFileAndSetCurrentFile(data).then((result => {
      //console.log('resultado',result)
      this.dismissLoading();
      this.openModal(result);
    })).catch(err => {
      this.dismissLoading();
      this.alertService.present('toast',{ message: "La lectura del documento falló, intenta mas tarde.",duration: 3500})
    })
  
  }

  openContainer(containerId,index){
    var element = document.getElementById(containerId+index);
    element.classList.toggle("closed");
    element.classList.toggle("full-height");
    this.toggleArrow(index,containerId)
  }

  toggleArrow(index,containerId){
    const element = document.getElementById(containerId+' icon '+index);
    element.classList.toggle("rotate-icon")
  }


}
