import { Injectable } from '@angular/core';
@Injectable()

export class WebStorageService {
  private webStorage = window.localStorage;

  constructor() {

  }

  /* Local Storage */

  localStorageSet(valueName,value){
    localStorage.setItem(valueName, JSON.stringify(value));
  }

  localStorageGet(valueName){
    var returnedValue = JSON.parse(localStorage.getItem(valueName))
    return returnedValue;
  };

  localStorageClear(){
    localStorage.clear();
  }

  localStorageRemove(valueName){
    localStorage.removeItem(valueName);
  }

  /* Session Storage */

  
}