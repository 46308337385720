import { Injectable } from '@angular/core';
import { PermissionService } from './permission.service';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService{

  public appPages = [
    {
      title: 'Inicio',
      url: '/home',
      icon: 'home',
      resource: 'calendar',
      position: 1
    },
    {
      title: 'Causas',
      url: '/causa-ref',
      icon: 'file-tray-full',
      resource: 'causa',
      position: 2
    },
    {
      title: 'Análisis inteligente de PDF',
      url: '/pdf-analyzer',
      icon: 'book',
      resource: 'pdf-analyzer',
      position: 12
    },
    {
      title: 'Consultas',
      url: '/consultation',
      icon: 'man',
      resource: 'consultation',
      position: 3
    },
    {
      title: 'Almacenamiento',
      url: '/storage',
      icon: 'cloud',
      resource: 'storage',
      position: 4
    },
    {
      title: 'Formatos',
      url: '/format',
      icon: 'document-text',
      resource: 'format',
      position: 5
    },
    {
      title: 'Áreas',
      url: '/area',
      icon: 'library',
      resource: 'area',
      position: 6
    },
    {
      title: 'Usuarios',
      url: '/user',
      icon: 'person',
      resource: 'users',
      position: 7
    },
    {
      title: 'Permisos',
      url: '/permissions',
      icon: 'people',
      resource: 'roles',
      position: 8
    },
    {
      title: 'Configuración',
      url: '/configuration/causa',
      icon: 'settings',
      resource: 'causa-client-config',
      position: 9
    },
  ];

  constructor(
    private permissionService: PermissionService,
    private eventsService: EventsService) {
      this.eventsService.addEventEmitter('appPages');

  }

  getPages(){
    this.permissionService.allowedToRead(this.appPages).then( res =>{
      if(res){
        this.eventsService.emitEventValue('appPages', res)
      }else{
        this.eventsService.emitEventValue('appPages', this.appPages)
      }

    });
    
  }

  emitPages(value){
    this.eventsService.emitEventValue('appPages',value);
  }


  public hideSidebar() {
    var menu = document.querySelector('ion-menu');
    menu.hidden = true;
    menu.close();

    var principalBar = document.getElementById('main-bar');
    principalBar.hidden = true;
  }

  public showSidebar() {
    this.getPages();

    var menu = document.querySelector('ion-menu');
    menu.hidden = false;
    menu.open();

    var principalBar = document.getElementById('main-bar');
    principalBar.hidden = false;
  }
}
