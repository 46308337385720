import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';
import { SocketService } from './socket.service';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {  
  private url: string = environment.host;
  public notificationsArray$ : BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private user : any;

  constructor(
    private http:HttpClient,
    private login:LoginService,
    private socketService : SocketService,
    private storage : Storage) {    

      this.storage.get("user").then( user =>{
        this.user = user;
        this.getNotifications();
      } )

    }

  async getNotifications() {

      let socket = this.socketService.connect();
  
      this.socketService.subscribeToOldNotifications(socket).subscribe((notifications)=> {
        //console.log('NotificationService --> subscribeToListEnterpriseNotifications:', notifications);
        this.notificationsArray$.next(notifications)
      
      })
  
      //cuando hay una sola
      this.socketService.subscribeToNewNotification(socket).subscribe((notification)=> {
        //console.log('NotificationService -->  new notification:', notification);
        this.notificationsArray$.next(this.notificationsArray$.getValue().concat([notification]) )
      })
  
      this.socketService.joinEnterpriseNotificationsRoom(socket, this.user["id"])
    
  }

  getNotificationsArrayObservable(){
    return this.notificationsArray$.asObservable();
  }

  list() {    
    return this.http.get<any[]>(`${this.url}/notification/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
      .set('userId' , this.login.getUserId())
      .set('enterpriseId' , this.login.getEnterprise())
    });
  }
  read(notificationId) {    
    const payload = {
      notificationId,
      userId: this.login.getUserId()
    }
    //console.log(payload);
    return this.http.post<any[]>(`${this.url}/notification/read` ,payload, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())    
    });
  }
  
  listByCausa() {    
    const user = this.login.getUserId();
    const params = {user}
    
    return this.http.post<any[]>(`${this.url}/notification/causa` ,params, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())
      .set('enterpriseId' , this.login.getEnterprise())      
    });
  }
  /*
  getOne(id){
    return this.http.get<any[]>(`${this.url}/cliente/${id}` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  update(id,cliente){
    delete cliente.__v;
    return this.http.patch<any[]>(`${this.url}/cliente/${id}` , cliente,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  insert(cliente){
    cliente.status = true;
    return this.http.post<any[]>(`${this.url}/cliente/` ,cliente, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
*/
}
