import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';

interface auth {
  accessToken : string;
  refreshToken : string;
  userId : string;
}

@Injectable()

export class AuthService {

  private url: string = environment.host;

  constructor(private http: HttpClient, private login : LoginService) { }

  async logUser(user){
    //this.url = "https://api.vase.cl";
    return this.http.post<auth>(`${this.url}/auth/`, user, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

  async changeInfo(user, password){
  
    var form = {
                      user: user,
                      password: password,
               }

      return this.http.post<any[]>(`${this.url}/auth/changeInfo/` ,form, {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization' , this.login.getToken())      
      });
  }

  async checkEmail(email){
    var form = {
      email:email
    }
    return this.http.post<auth>(`${this.url}/auth/emailExist/`, form, {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    });
  }

}