export const CheckPlanPermissions = (featureName: string) => {
  let isAllowed = undefined;
  return (target: Object, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args: any[]) {
      isAllowed = await this.planPermissionService.checkPlanPermission(featureName)

      if (!isAllowed) {
        this.router.navigate(['/plans'], { replaceUrl: true });
        this.alertService.present('toast', {
          message: "Tu plan no tiene disponible esta caracteristica 😞. Revisa los planes que tenemos disponibles &#9757", 
          duration: 5000,
          position: 'bottom'
        })
      } else {
        return originalMethod.apply(this, args);
      }

    }
    return descriptor;
  }
}

export const CheckReadPermission = (featureName: string) => {

  const featureDictionary = {
    "format": { name: 'formatos' },
    "consultation": { name: 'consultas' },
    "causa": { name: 'causas' },
    "calendar": { name: 'calendario' },
    "area": { name: 'areas' },
    "roles": { name: 'roles' },
    "storage": { name: 'almacenamiento' },
    "users": { name: 'usuarios' },
    "pdf-analyzer": { name: 'analisis de PDF' },
    "logbook": {name: "bitácora"}
  }
  let isAllowed = undefined;

  return (target: Object, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args: any[]) {

      isAllowed = await this.permissionService.checkPermission(featureName, 'r')
      if (!isAllowed) {
        this.router.navigate(['/configuration'], { replaceUrl: true });
        this.alertService.present('alert', {
          header: 'Error de permisos',
          subHeader: ' ',
          message: `No tienes los permisos para entrar a ${featureDictionary[featureName].name}`,
          buttons: ['Ok']
        })
      } else {
        return originalMethod.apply(this, args);
      }


    }
    return descriptor;
  }
}
