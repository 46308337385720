import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {  
  private url: string = environment.host;

  constructor(private http:HttpClient,private login:LoginService) {    
  }
  async listByEnterprise() { 
    return this.http.get<any[]>(`${this.url}/users/listByEnterprise` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())
      .set('userId' , this.login.getUserId())
      .set('enterpriseId' , this.login.getEnterprise())   
    });
  }

  async getOne(id){
    const r = await this.login.start();
    return this.http.get<any[]>(`${this.url}/users/${id}` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())
       .set('userId' , this.login.getUserId())
       .set('enterpriseId' , this.login.getEnterprise())         
    });
  }

  generateEmailConfirmationCode(){
    return this.http.post<any[]>(`${this.url}/users/generateEmailConfirmationCode/${this.login.getUserId()}`,{},{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())
    });
  }

  confirmEmail(code: number){
    return this.http.post<any[]>(`${this.url}/users/confirmEmail/${this.login.getUserId()}`,{code},{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())
    });
  }

  update(id,user){
    delete user.__v;
    if(user.token){
      delete user.token;
    }
    if(user.password){
      delete user.password;
    }

    //console.log('este es el usuario que se va a mandar:',user);

    return this.http.patch<any[]>(`${this.url}/users/${id}`, user,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())  
    });
  }

  updatePassword(id,user,newPassword){
    delete user.__v;
    var user = user;
    
    user.password = newPassword

    if(user.token){
      delete user.token;
    }
    
    return this.http.patch<any[]>(`${this.url}/users/${id}` , user,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

  insert(user){
    user.status = true;
    user['enterpriseId'] = this.login.getEnterprise();
    user['permissionLevel'] = 1;

    return this.http.post<any[]>(`${this.url}/users/` ,user, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

}
