import { Injectable } from '@angular/core';

@Injectable()

export class ColorGeneratorService {

  constructor() { }

  generateColor(firstLetter: string, secondLetter: string) {
    const a = firstLetter.charCodeAt(0);
    const b = secondLetter.charCodeAt(0);

    const c = Math.abs(a - b);
    const d = (a + b) % 255;
    const e = (a * b) % 255;
    return this.rgbToHex(c, d, e);
  }

  rgbToHex(r, g, b) {
    return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }
};
