import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class MimeTypeService {
    mimeTypes = {
        'ffd8ffe0': 'image/jpeg',
        '89504e47': 'image/png',
        '47494638': 'image/gif',
        '424d': 'image/bmp',
        '25504446': 'application/pdf',
        'd0cf11e0a1b11ae1': 'application/vnd.ms-word',
        '504b0304': 'application/vnd.ms-excel'
    };
    maxLengthOfType = 4;

    async getMimeTypeFromBuffer(blob: any) {
        const buffer = await blob.arrayBuffer();
        const bytes = new Uint8Array(buffer);
        const hex = bytes.slice( 0 , this.maxLengthOfType ).reduce((acc, byte) => acc + byte.toString(16), '');

        return this.mimeTypes[hex] || null;
    }
}
