import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';
import { LogEntry } from '../causa-ref/logbook/logbook.page';

@Injectable({
  providedIn: 'root'
})
export class CausaService {  
  private url: string = environment.host;  
  private causa;
  constructor(
    private http:HttpClient,
    private login:LoginService,
    private storage: Storage,
    private loginService: LoginService) {    

  }

  list(id:string) {    
    return this.http.get<any[]>(`${this.url}/causa/area/${id}` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  getOne(id){
    return this.http.get<any[]>(`${this.url}/causa/${id}` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  update(id,causa){
    delete causa.__v;
    return this.http.patch<any[]>(`${this.url}/causa/${id}` , causa,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  insert(causa){
    causa.status = true;
    return this.http.post<any[]>(`${this.url}/causa/` ,causa, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  setCausa(currentCausa){
    //console.log('current causa',currentCausa)
    this.causa = currentCausa;
  }
  getCausa(){
    return this.causa;
  }
  async getUser(){
    return await this.storage.get('user');
  }
  getCausasByUser(id:string){
    return this.http.get<any[]>(`${this.url}/causa/listByUser/${id}` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  updateCurrentCausa(){
    return new Promise( (resolve,reject) => {
      this.update(this.causa._id,this.causa).subscribe( result =>{
        resolve(result);
      })
    })
  }
  listByUserAllAreas(){
    return this.http.get<any[]>(`${this.url}/causa/all/listByUser` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  getCausasByClientId(clientId : String){
    return this.http.get<any[]>(`${this.url}/causa/client/${clientId}` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

  getCausaByCode(code : String){
    return this.http.post<any[]>(`${this.url}/causa/getCausaByCode/`,{code: code} , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }
  generateCode( causaId: string){
    
    let enterpriseId = this.login.getEnterprise();
    let data = {
      enterpriseId,
      causaId,
    }
    return this.http.post<any[]>(`${this.url}/causa/generateCode/`, data , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

  sendEmailToClient(code, email){

    let data = {
      code,
      email,
    }
    //console.log(data);
    return this.http.post<any[]>(`${this.url}/causa/sendEmailToClient/`, data , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

  createLogEntry(logEntry : string) : Promise<any>{
    const {name, id} = this.loginService.getUserObject();
    const tempLogObject  : LogEntry = { createDate: new Date(), message: logEntry, isPublic: false, insertedBy: {user:  name, userId: id}};

    let causaLogs = this.getCausa().logBook || [];
    let updatedLogs = [...causaLogs, tempLogObject];

    this.causa.logBook = JSON.parse(JSON.stringify(updatedLogs));
    
    return new Promise( (resolve,reject) => {
      this.update(this.causa._id,this.causa).subscribe( result =>{
        resolve(result);
      })
    })
  }

  removeLogEntry(logIndex : number){

    let causa = this.getCausa();
    causa.logBook.splice(logIndex, 1);

    return new Promise( (resolve,reject) => {
      this.update(this.causa._id,this.causa).subscribe( result =>{
        resolve(result);
      })
    })
  }

  editLogEntry(logEntry: LogEntry){

    logEntry.isPublic = !logEntry.isPublic
    
    return new Promise( (resolve,reject) => {
      this.update(this.causa._id,this.causa).subscribe( result =>{
        resolve(result);
      })
    })
  }

  
}
