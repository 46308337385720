import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { environment } from 'src/environments/environment';
import { RolesService } from './roles.service';
import { Router } from '@angular/router';
import { EventService } from './event.service';
import { EventsService } from './events.service';

interface PermissionType{
  c: Boolean,
  r: Boolean
  u: Boolean,
  d: Boolean
}

@Injectable({
  providedIn: 'root'
})

export class PermissionService {
  private permissions;

  constructor(private eventsService : EventsService,private rolesService: RolesService, private storage : Storage, private router: Router) {

  }

  async start(){
    const val = await this.storage.get('user');
    if(val){
      const result = await this.rolesService.getRoleById(val.roleId).toPromise();
      this.permissions = result;
      return true;
    }else{
      return false;
    }
  }

  public setPermission(permissions){
    this.permissions = permissions;
  }

  public getPermission(){
    return this.permissions;
  }
  public firstAllowedToRead(permissions,appPages){
    let hashPages  = {};
    for(const page of appPages){
      hashPages[page.resource] = page.url;
    }
    const resources = permissions.resources
    
    for(const permission in resources){      
      if(resources[permission]?.permissions?.r && hashPages[permission]){        
        return hashPages[permission];        
        
      }
    }
    return '/configuration';
  }
  public async allowedToRead(appPages){
    if (!this.permissions) {
      const result = await this.start(); // Espera a que this.permissions se inicialice antes de continuar      
      if(!result){
        return [];
      }
    }    
    let hashPages  = {};
    for(const page of appPages){
      hashPages[page.resource] = page;
    }
    const resources = this.permissions.resources
    const allowedPages = [];
    
    for(const permission in resources){      
      if(resources[permission]?.permissions?.r && hashPages[permission]){        
         allowedPages.push(hashPages[permission]);                
      }
    }
    const sortedPages = allowedPages.sort((a,b) => a.position - b.position)
    return sortedPages;
  }
  public async checkPermission(name: string, permissionType: string): Promise<boolean> {
    if (!this.permissions) {
      const result = await this.start(); // Espera a que this.permissions se inicialice antes de continuar
      if(!result){
        return false;
      }
    }
    const resource = this.permissions.resources[name];
    return resource.permissions[permissionType];
  }
}