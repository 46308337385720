
import { Subject } from 'rxjs';
import { Component, ComponentRef, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlertController, ModalController, ToastController } from '@ionic/angular';

class AlertStrategy {
    async present(data) {
      throw new Error('Método no implementado');
    }
  }
  
  class AlertControllerStrategy extends AlertStrategy {
    constructor(private alertController: AlertController) {
      super();
    }
  
    async present({ header, subHeader, message, buttons, inputs}) {
      const data = {
        header : header || " ",
        subHeader : subHeader || " ",
        message: message || " ",
        buttons: buttons || ['OK'],
        inputs: inputs || []
      };
      const alert = await this.alertController.create(data);
      alert.present();
    }
  }
  
  class ToastControllerStrategy extends AlertStrategy {
    constructor(private toastController: ToastController) {
      super();
    }
  
    async present( { message, duration ,position,} ) {
      const data = {
        message: message || "Mensaje de ejemplo",
        duration: duration || 2500,
        position: position || 'bottom'
      }

      const toast = await this.toastController.create(data);
  
      await toast.present();
    }
  }
  



@Injectable({
  providedIn: 'root'
})
export class AlertService {  

  private url: string = environment.host;  
  private strategies = {
    alert: new AlertControllerStrategy(this.alertController),
    toast: new ToastControllerStrategy(this.toastController)
  };

  constructor(private alertController: AlertController, private modalController: ModalController, private toastController: ToastController) {}

  async present(controller: string, ...args: any[]) {
    const strategy = this.strategies[controller];

    if (!strategy) {
      throw new Error(`La estrategia ${controller} no está definida`);
    }

    await strategy.present(...args);
  }




}

