import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../_services/login.service';
import { Storage } from '@ionic/storage';
import { UserService } from '../_services/user.service';
import { ColorGeneratorService } from '../_services/colorGenerator.service';
import { EventsService } from '../_services/events.service';
import { CheckPlanPermissions, CheckReadPermission } from '../_decorators/decorators';
import { PlanPermissionService } from '../_services/planPermissions.service';
import { AlertService } from '../_services/alerts.service';
import { PermissionService } from '../_services/permission.service';
import { NotificationService } from '../_services/notification.service';
import { SocketService } from '../_services/socket.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user;
  firstLetter;
  secondLetter;
  imageLoading = true;
  notifications = [];
  smallMenu = false;
  toBeRead: number = 0;

  segmentChanged(ev: any) {
    //console.log('Segment changed', ev);
  }

  showNotificationModal = false;
  showQuickStart = false;

  constructor(
    private notificationService: NotificationService ,
    private router: Router,
    private storage: Storage,
    private userService: UserService,
    private colorGenerator: ColorGeneratorService,
    private eventsService: EventsService,
    private planPermissionService: PlanPermissionService,
    private alertService: AlertService,
    private permissionService: PermissionService,
    private socketService: SocketService

  ) { }

  ngOnInit() {
    
    this.eventsService.addEventEmitter('userLetter');
    this.eventsService.getEventEmitter('userLetter').subscribe(user => {
      this.setUser(user);
    })

    this.eventsService.addEventEmitter('notification');
    this.eventsService.getEventEmitter('notification').subscribe(value => {
      this.setNotificationStatus();
    })

    this.eventsService.addEventEmitter('quick-start');
    this.eventsService.getEventEmitter('quick-start').subscribe(value => {
      this.setQuickStartStatus();
    })
    //
    this.storage.get('user').then((val) => {
      //console.log('val1',val);
      if (val) {
        this.userService.getOne(val['_id']).then(res => {
          res.subscribe(user => {          
            this.setUser(user);            
            this.initData();
            // setInterval(()=>{              
            //   this.initData();
            // }, 15000)
          })
        })
      }
    })
  }
  initData(){
    // this.notifications = [];
    // this.notificationService.list().subscribe(data=>{
    //   //console.log('formated readed data', data)
    // })
    // this.notificationService.listByCausa().subscribe(data=>{
    //   this.prepareData(data);
    // })
   
    this.notificationService.getNotificationsArrayObservable().subscribe(data =>{
      //console.log("HEADER COMPONENT --> NOTIFICATIONS ", data);
      this.filterReadedNotifications(data)
    })  

   
  }

  filterReadedNotifications(notificationsArray){
    this.notifications = notificationsArray;
    this.getReadedNotitications();
  }

  recalculateReadedNotifications(e){
    if(e == true){
      this.getReadedNotitications();
    }
  }

  getReadedNotitications(){
    const toBeReadList = this.notifications.filter( notification=>{
      return !notification.data.readedBy.find( user => user === this.user.id)
    })
    //console.log("toBeReadList",toBeReadList);
    this.toBeRead = toBeReadList.length;
  }
  
  setUser(user) {
    this.user = user;
    this.firstLetter = this.user['name'].charAt(0);
    this.secondLetter = this.user['name'].charAt(1);
    this.imageLoading = false;

    document.documentElement.style.setProperty('--primary-color', this.colorGenerator.generateColor(this.firstLetter, this.secondLetter));
  }

  @CheckPlanPermissions('notifications')
  changeNotificationStatus() {
    this.showNotificationModal = !this.showNotificationModal
  }

  setNotificationStatus() {
    if(this.showNotificationModal){
      this.showNotificationModal = false;
      return true
    } 
  }

  setQuickStartStatus() {
    if(this.showQuickStart){
      this.showQuickStart = false;
      return true
    } 
  }

  changeQuickStartStatus() {
    this.showQuickStart = !this.showQuickStart;
  }

  navigateTo(route: string){
    this.router.navigate([route], { replaceUrl: true});
  }
  @CheckReadPermission('pdf-analyzer')
  navigateToPdfAnalyzer(){
    this.router.navigate(['pdf-analyzer'], { replaceUrl: true});
  }

  navigateToProfile() {
    this.router.navigate(['configuration'], { replaceUrl: true });
  }

  changeMenuSize() {
    const menuSize = this.smallMenu ? '19vw' : '80px';
    this.eventsService.emitEventValue('menu', { status: this.smallMenu, size: menuSize });
    this.smallMenu = !this.smallMenu;
  }

}
