import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Storage } from '@ionic/storage';
import { MenuService } from './menu.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService,
        private router: Router,
        private storage: Storage,
        private menuService: MenuService
    ) { }

    async canActivate(): Promise<boolean> {
        const val = await this.storage.get('user');
        if (val) {
            this.menuService.showSidebar();
            return true;
        } else {
            this.menuService.hideSidebar();
             this.router.navigate(['/login'], { replaceUrl: true });
            return false;
        }
    }
}