import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http'; //Protocolo http para que los servicios se comuniquen con la api
import { ReactiveFormsModule,FormsModule, FormBuilder } from '@angular/forms'; //esto es para los formularios
import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Storage } from '@ionic/storage';
import { NgSelectModule } from '@ng-select/ng-select';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

//servicios

import { AuthService } from './_services/auth.service';
import { LoginService } from './_services/login.service';
import { ValidationService } from './_services/validation.service';
import { DataStorageService } from './_services/dataStorage.service';

import { EventService } from './_services/event.service'
import { HeaderComponent } from './header/header.component'
import { NotificationComponent} from './notification/notification.component';
import { QuickStartPage } from './quick-start/quick-start.page';
import { ExcelService } from './_services/excel.service';
import { WebStorageService } from './_services/webStorage.service';
import { FileService } from './_services/file.service';
import { RegisterService } from './_services/register.service';
import { RecoverPasswordService } from './_services/recoverPassword.service';
import { ColorGeneratorService } from './_services/colorGenerator.service';
import { EventsService } from './_services/events.service'
import { InfoService } from './_services/info.service';
import { MimeTypeService } from './_services/mimeType.service';
import { PermissionService } from './_services/permission.service';
import { CommonComponentModule } from './components.module';
import { AlertService } from './_services/alerts.service';
import { PlanPermissionService } from './_services/planPermissions.service';
import { UtilsService } from './_services/utils.service';

//directive


@NgModule({
  declarations: [AppComponent, HeaderComponent, NotificationComponent, QuickStartPage],
  entryComponents: [],
  imports: [BrowserModule, 
            CommonComponentModule,
            AppRoutingModule,
            NgSelectModule,
            HttpClientModule,
            IonicModule.forRoot(),
            IonicStorageModule.forRoot()],
  providers: [
    AuthService,
    LoginService,
    DataStorageService,
    ValidationService,
    ExcelService,
    RegisterService,
    FileService,
    WebStorageService,
    RecoverPasswordService,
    EventService,
    EventsService,
    InfoService,
    NgSelectModule,
    ColorGeneratorService,
    PermissionService,
    PlanPermissionService,
    AlertService,
    UtilsService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
  schemas :[CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
