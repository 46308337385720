import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RolesService {  
  private url: string = environment.host;
  constructor(private http:HttpClient,private login:LoginService) {    
  }

  createRole(role){
    role.enterpriseId = this.login.getEnterprise();
    return this.http.post<any[]>(`${this.url}/role/`,role, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

  updateRole(role: any){
    return this.http.patch<any[]>(`${this.url}/role/${role.id}`,role, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

  deleteRole(role: any){
    //console.log('role:', role)
    return this.http.delete<any[]>(`${this.url}/role/${role.id}`,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

  getRoleById(id) {    
    return this.http.get<any[]>(`${this.url}/role/${id}`, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())      
    });
  }

  listByEnterprise() {    
    const enterpriseId = this.login.getEnterprise();
    return this.http.get<any[]>(`${this.url}/role/listByEnterprise/${enterpriseId}`,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken()) 
    });
  }

  getCurrentResources(){
    return this.http.get<any[]>(`${this.url}/role/resource/`,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken()) 
    });
  }
}
