import { Component, OnInit,Input } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { FileService } from '../_services/file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CausaService } from '../_services/causa.service';
@Component({
  selector: 'upload-box',
  templateUrl: './upload-box-component.component.html',
  styleUrls: ['./upload-box-component.component.scss'],
})
export class UploadBoxComponent implements OnInit {

  @Input() type : string;
  @Input() form ;
  @Input() param : string;
  @Input() size : string;
  @Input() title : string = "Subir archivos";
  loading : boolean = false;
  @Input() logData: String;

  constructor(private domSanitizer  : DomSanitizer, private fileService : FileService, private causaService: CausaService) { }

  ngOnInit() {
    //console.log('param',this.param)
  }

  
  public files: NgxFileDropEntry[] = [];

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {        
          const formData = new FormData();
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {                                                
            //console.log(file);
            const url = window.URL.createObjectURL(file); 
            
            droppedFile['blobFile'] = url;
            // this.fileService.sendFile(formData).subscribe( value => {            
            //   this.fileService.fileUploaded = value;  
            //   if(this.param) this.form.controls[this.param].setValue(value);
            // })
          });
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        //console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public uploadFile(index){
    this.loading = true;
    const formData = new FormData();
    const fileEntry = this.files[index].fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {                                                
      //console.log(file);
      const fileName = file.name.substr(0, file.name.lastIndexOf('.')) || file.name;
      formData.append('file', file );            
      formData.append('fileName', fileName);
      formData.append('type', this.type || "general" );

      this.fileService.sendFile(formData).subscribe( value => {        
        this.fileService.fileUploaded = value;  
        this.loading = false
        if(this.param) this.form.controls[this.param].setValue(value);
        this.causaService.createLogEntry(`Se subió el archivo :'${fileName}', en la carpeta : ${this.logData}. `)
      })

    });
  }
  
  photoURL(imageUrl) {
    return this.domSanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  public fileOver(event){
    //console.log("listo ya lo subi",event);
   // this.fileService.uploadedFile = event.file;
  }

  public fileLeave(event){
    //console.log(event);
  }

  public deleteFile(index){
    //console.log(this.files[index]);
    this.files.splice(index, 1);
  }
}
