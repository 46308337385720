import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';
import { MimeTypeService } from 'src/app/_services/mimeType.service';

@Injectable()

export class FileService {

  private url: string = environment.files;
  public fileUploaded;
  public segments = {};
  private obligatedSegments: string[] = ["Principal", "Gastos"];
  private segmentEmitter = new EventEmitter();

  constructor(private http: HttpClient, private mimeType: MimeTypeService, private login: LoginService) { }
  isRequired(segment: string) {
    return this.obligatedSegments.includes(segment)
  }
  sendAnalyze(formData) {
    return this.http.post<any>(`${environment.fileAnalyzer}/pdf/process`, formData, {
      headers: new HttpHeaders()      
      .set('enterpriseId' , this.login.getEnterprise())      
    });
  }
  sendChat(question) {
    const payload = {question}
    return this.http.post<any>(`${environment.fileAnalyzer}/pdf/chat`, payload, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('enterpriseId' , this.login.getEnterprise())      
    });
  }
  sendFile(formData) {
    //console.log('formData:', formData);
    formData.append('origin', environment.origin);
    formData.append('enterpriseId', this.login.getEnterprise());
    return this.http.post<any>(`${this.url}/files/uploadFile`, formData, {});
  }
  readFile(formData) {
    return this.http.post<any>(`${this.url}/files/getFile`, formData, { responseType: 'blob' });
  }
  async executeReadLogic(v, ps) {    
    if (v == 2 || v == "2") {
      if (ps.document) {
        const docto = ps.document.data;
        return await this.readFileAndSetCurrentFile(docto);        
      }
    }
  }
  async readFileAndSetCurrentFile(docto: any) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("hash", docto.hash);
      formData.append("iv", docto.iv);
      formData.append("srv", docto.srv);
      this.readFile(formData).subscribe(async (file) => {
        const mimeType = await this.mimeType.getMimeTypeFromBuffer(file);
        //console.log('mimeType', mimeType);
        if (mimeType) {          
          const temporalFile = window.URL.createObjectURL(new Blob([file], { type: mimeType }));
          resolve(temporalFile)
          //console.log('2:', temporalFile);
        } else {
          reject(null);
          return null;
        }
      },err=>{
        reject(null);
      })
    })

  }

  setSegment(segment: string, value: Array<Object>) {
    this.segments[segment] = value;
  }
  deleteSegment(segment) {
    delete this.segments[segment];
  }
  resetSegment() {
    this.segments = {};
  }
  getSegments() {
    return this.segments;
  }
  getSegmentEmitter() {
    return this.segmentEmitter;
  }
  emitNavChangeEvent(segment: string) {
    //console.log('segment', segment);
    this.segmentEmitter.emit(segment);
  }
  initSegments(causa) {
    const { segments } = causa;
    for (const segment in segments) {

      this.setSegment(segment, segments[segment])
    }
  }

}