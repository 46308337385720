import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  private url: string = environment.host;
  constructor(private http: HttpClient, private login: LoginService) {
  }

  getPlansByHiddenStatus(isHidden = false){
    let data = {
      isHidden
    }
    return this.http.post<any[]>(`${this.url}/plan/listByHiddenStatus`, data ,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())
      .set('enterpriseId' , this.login.getEnterprise())
    });
  }

  getPlanByEnterpriseId() {
    return this.http.post<any[]>(`${this.url}/plan/getByEnterpriseId`,{},{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization' , this.login.getToken())
      .set('enterpriseId' , this.login.getEnterprise())
    });
  }
}
