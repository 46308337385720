import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { DataStorageService } from './dataStorage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class RegisterService {
  private url: string = environment.host;
  private enterprise : string = "";
  private user : string = "";

  constructor(private router:Router,private dataStorage : DataStorageService ,private http:HttpClient) {
    
  }

  async insertEnterprise(form){
    form['key'] = 'Bienvenidos a Jude';
    form['status'] = true;
    return this.http.post<any[]>(`${this.url}/enterprise/`,form, {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    })    
  }

  async insertUser(form,enterpriseId){
    form['enterpriseId'] = enterpriseId;
    form['status'] = true;
    form['permissionLevel'] = 2048;
    
    return this.http.post<any[]>(`${this.url}/users/byRegister/`,form, {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    })    
  }

}