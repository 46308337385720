import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_services/authGuard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule),
  },
  {
    path: 'password-recovery',
    loadChildren: () => import('./password-recovery/password-recovery.module').then( m => m.PasswordRecoveryPageModule)
  },
  {
    path: 'causa',
    loadChildren: () => import('./causa/causa.module').then( m => m.CausaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tribunal',
    loadChildren: () => import('./tribunal/tribunal.module').then( m => m.TribunalPageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'file-upload',
  //   loadChildren: () => import('./file-upload/file-upload.module').then( m => m.FileUploadPageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then( m => m.UserPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'area',
    loadChildren: () => import('./area/area.module').then( m => m.AreaPageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'study',
  //   loadChildren: () => import('./study/study.module').then( m => m.StudyPageModule)
  // },
  {
    path: 'consultation',
    loadChildren: () => import('./consultation/consultation.module').then( m => m.ConsultationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'format',
    loadChildren: () => import('./format/format.module').then( m => m.FormatPageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'upload-box',
  //   loadChildren: () => import('./upload-box/upload-box.module').then( m => m.UploadBoxPageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'display',
    loadChildren: () => import('./display/display.module').then( m => m.DisplayPageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'client',
  //   loadChildren: () => import('./client/client.module').then( m => m.ClientPageModule)
  // },
  {
    path: 'color-generator',
    loadChildren: () => import('./color-generator/color-generator.module').then( m => m.ColorGeneratorPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'clientes',
    loadChildren: () => import('./client-code/client-code.module').then( m => m.ClientCodePageModule)
  },
  {
    path: 'quick-start',
    loadChildren: () => import('./quick-start/quick-start.module').then( m => m.QuickStartPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'causa-ref',
    loadChildren: () => import('./causa-ref/causa-ref.module').then( m => m.CausaRefPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'storage',
    loadChildren: () => import('./storage/storage.module').then( m => m.StoragePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'permissions',
    loadChildren: () => import('./permissions/permissions.module').then( m => m.PermissionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'configuration',
    loadChildren: () => import('./configuration/configuration.module').then( m => m.ConfigurationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pdf-analyzer',
    loadChildren: () => import('./pdf-analyzer/pdf-analyzer.module').then( m => m.PdfAnalyzerPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'plans',
    loadChildren: () => import('./plans/plans.module').then( m => m.PlansPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'error/:id',
    loadChildren: () => import('./error/error.module').then( m => m.ErrorPageModule),
     canActivate:[AuthGuard]
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: '**',
    redirectTo: 'error/404',
    pathMatch: 'full',
  },

  /*
  {
    path: 'example',
    loadChildren: () => import('./example/example.module').then( m => m.ExamplePageModule)
  },
  */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
