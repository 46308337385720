import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { LoginService } from './login.service';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: Socket;
  private user : any ;
  private url: string = environment.socketNotificationApi;  

  constructor(private loginService : LoginService, private storage : Storage) { 
  }

  connect(): Socket{
    return io(this.url); // Reemplaza con la URL de tu servidor Socket.IO
  }

  disconnect(socket): void {
    socket.disconnect();
  }

  /* Traer notificaciones de la empresa */

  joinEnterpriseNotificationsRoom(socket,userId): void {
      // const userId = this.loginService.getUserId();
      socket.emit('join-user-notifications', userId);

  }

  subscribeToOldNotifications(socket): Observable<any>{
    return new Observable<any>(observer => {
      socket.on('user-old-notifications', (notifications) => {
        observer.next(notifications);
      });
    });
  }

  subscribeToNewNotification(socket): Observable<any>{
    return new Observable<any>(observer => {
      socket.on('user-new-notifications', (notifications) => {
        observer.next(notifications);
      });
    });
  }
}
