import { Component, OnInit, Input } from '@angular/core';
import { ColorGeneratorService } from '../_services/colorGenerator.service';

@Component({
  selector: 'app-color-generator',
  templateUrl: './color-generator.page.html',
  styleUrls: ['./color-generator.page.scss'],
})
export class ColorGeneratorPage implements OnInit {
  @Input() firstLetter = '';
  @Input() secondLetter = '';

  constructor(private colorGenerator: ColorGeneratorService,) { }

  ngOnInit() {
    document.documentElement.style.setProperty('--primary-color',  this.colorGenerator.generateColor(this.firstLetter,this.secondLetter));
  }

}