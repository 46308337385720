import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-display',
  templateUrl: './display.page.html',
  styleUrls: ['./display.page.scss'],
})
export class DisplayPage implements OnInit {
  currentFile;
  constructor(private navParams : NavParams,private modalCtrl : ModalController) { }
  ngOnInit() {    
    this.currentFile = this.navParams.get("currentFile");
  }
  getLink(){
    return this.currentFile;
  }
  closeModal(){
    this.modalCtrl.dismiss();
  }
}
