import { Directive, Input, ElementRef, Renderer2, SimpleChanges } from '@angular/core';
import { PermissionService } from '../_services/permission.service';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective {

  @Input() resourceName: string;
  @Input() permissionType: string;

  constructor(private el: ElementRef<any>, private renderer: Renderer2, private permissionService: PermissionService) { }

  async ngOnChanges(changes: SimpleChanges) {
    if(changes){
      const hasPermission = await this.permissionService.checkPermission(this.resourceName, this.permissionType);
      if (!hasPermission) {
        this.renderer.removeChild(this.el.nativeElement.parentNode, this.el.nativeElement);
      }
    }
  }

}