// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  origin: "jude",
  // host:"http://localhost:4200",
  host:"https://api.judi.cl",
  //files:"https://secure-files.vase.cl",
  calendapp : "https://calendapp-xgih3.ondigitalocean.app",
  files:"https://global-storage-95lii.ondigitalocean.app",
  payment : "http://localhost:3800/apiFlow",
  fileAnalyzer : "https://ia-pinecone-file-gpt-4cfdb.ondigitalocean.app",
  clientStorage : "https://client-storage-absly.ondigitalocean.app",
  socketNotificationApi: "https://socket-api-jude-egczf.ondigitalocean.app"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
