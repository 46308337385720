import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})

export class LoginService {
  private token: string = "";
  private url: string = environment.host;
  private enterprise;
  private user;

  constructor(private router: Router, private storage: Storage, private http: HttpClient, private platform: Platform) {
    this.platform.ready().then(() => {
      this.start();
    })

  }

  async start() {

    const val = await this.storage.get('user');
    if (val) {
      this.setEnterprise(val['enterpriseId']);
      this.setToken(val['token']);
      this.user = val;
    } 
  }

  public getUrl() {
    return new Promise(resolve => {
      resolve(environment.host);
    });
  }

  public getEnterprise() {
    return this.enterprise;
  }
  public setEnterprise(em) {
    this.enterprise = em;
  }
  public setToken(t) {
    this.token = t;
  }
  public getToken() {
    return "Bearer " + this.token;
  }
  public setUser(user) {
    this.storage.set('user', user);
    this.user = user;
  }
  public getUserId() {
    // //console.log('?', this.user);
    return this.user['_id'];
  }
  getUserObject(){
    return this.user;
  }
  async getUser(form) {
    this.url = <string>await this.getUrl();
    //this.url = "https://api.vase.cl";

    return this.http.post<any[]>(`${this.url}/users/login/`, form, {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + this.token)
        .set('Content-Type', 'application/json')
    });
  }



}